<script setup lang="ts">
import ActivityFeed from '@app/components/activityFeeds/ActivityFeed.vue'
import UpdatedActivityFeed from '@app/components/activityFeeds/logs/UpdatedActivityFeed.vue'
import CallAppointmentActivityFeed from '@app/components/activityFeeds/recruitment/CallAppointmentActivityFeed.vue'
import DeclineReasonActivityFeed from '@app/components/activityFeeds/recruitment/DeclineReasonActivityFeed.vue'
import FunnelActivityFeed from '@app/components/activityFeeds/recruitment/FunnelActivityFeed.vue'
import FunnelBlockActivityFeed from '@app/components/activityFeeds/recruitment/FunnelBlockActivityFeed.vue'
import FunnelBlockStatusActivityFeed from '@app/components/activityFeeds/recruitment/FunnelBlockStatusActivityFeed.vue'
import RecruitmentAppointmentActivityFeed from '@app/components/activityFeeds/recruitment/RecruitmentAppointmentActivityFeed.vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import ProgressTrackerSteps from '@app/components/ui/progressTracker/ProgressTrackerSteps.vue'
import { useColor } from '@app/composables/useColor'
import { useChannel } from '@app/composables/useEcho'
import { useLocale } from '@app/composables/useLocale'
import type { Lead } from '@app/types/recruitment'
import { Bars4Icon, UserIcon } from '@heroicons/vue/20/solid'
import { computed, onMounted, type PropType } from 'vue'
import AppointmentDetails from '../appointments/AppointmentDetails.vue'
import LeadDetails from './LeadDetails.vue'

const { dbTranslate } = useLocale()
const { getFunnelBlockStatusColor } = useColor()

const props = defineProps({
    lead: {
        type: Object as PropType<Lead>,
        required: true,
    },
    dismissable: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['update', 'close', 'reload'])
const { subscribe } = useChannel('recruitment')

onMounted(() => {
    subscribe('.whatsapp.message.created', (payload: { lead_id: number; direction: string }) => {
        const isLeadFromMessageSelected = payload.lead_id === props.lead.id
        const isIncomingMessage = payload.direction === 'incoming'

        if (isLeadFromMessageSelected && isIncomingMessage) {
            emit('reload')
        }
    })
    subscribe('.call.completed', (payload: { lead_id: number }) => {
        if (payload.lead_id === props.lead.id) {
            emit('reload')
        }
    })
    subscribe('.call.record.stored', (payload: { lead_id: number }) => {
        if (payload.lead_id === props.lead.id) {
            emit('reload')
        }
    })
    subscribe('.email.sent', (payload: { mailable_id: number; mailable_type: string }) => {
        if (payload.mailable_type === 'recruitment_lead' && payload.mailable_id === props.lead.id) {
            emit('reload')
        }
    })
    subscribe('.recruitment.lead.status.updated', (payload: { newStatus: string }) => {
        if (payload.newStatus === 'new') {
            emit('reload')
        }
    })
})

const statusIncludingCallAttempts = computed(() => {
    let status = props.lead.funnel_block_status
    if (status.slug !== 'call-attempt') {
        return dbTranslate(status.name)
    }

    return dbTranslate(status.name) + ' (' + props.lead.call_attempts + ')'
})
</script>

<template>
    <DetailCard :model="lead" :dismissable="dismissable" @close="emit('close')">
        <div class="flex w-full flex-col items-center justify-between gap-8">
            <DetailCardTitle>{{ lead.full_name }}</DetailCardTitle>
            <div class="flex flex-col items-start gap-4">
                <DetailCardLabel :label="$t('attributes.funnel') + ' :'">
                    <span class="text-sm font-semibold uppercase leading-6"> {{ dbTranslate(lead.funnel.name) }} </span>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.status') + ' :'">
                    <div>
                        <Badge
                            size="lg"
                            class="leading-6"
                            :label="statusIncludingCallAttempts"
                            :color="getFunnelBlockStatusColor(lead.funnel_block_status.slug)"
                        />

                        <AppointmentDetails
                            v-if="lead.ongoing_call_appointment"
                            class="mt-2"
                            :from="lead.ongoing_call_appointment.from"
                            :to="lead.ongoing_call_appointment.to"
                        />

                        <AppointmentDetails
                            v-if="lead.ongoing_recruitment_appointment"
                            class="mt-2"
                            :from="lead.ongoing_recruitment_appointment.recruitment_availability.from"
                            :to="lead.ongoing_recruitment_appointment.recruitment_availability.to"
                        />
                    </div>
                </DetailCardLabel>
            </div>
            <ProgressTrackerSteps :steps="lead.funnel.blocks" :current_step="lead.funnel_block" class="mt-2" />
        </div>
        <template #actions>
            <slot></slot>
        </template>
        <template #tabs>
            <DetailCardTab tab="activity" :label="$t('activities.label')">
                <template #icon>
                    <Bars4Icon />
                </template>
                <ActivityFeed :activities="lead.combined_activity_feeds" id="activity">
                    <template #activity-log-updated="{ activity }">
                        <UpdatedActivityFeed :activity="activity" :casts="{ date_of_birth: 'date', branch_id: 'branch' }" />
                    </template>

                    <template #activity-log-funnel-updated="{ activity }">
                        <FunnelActivityFeed :activity="activity" />
                    </template>

                    <template #activity-log-block-updated="{ activity }">
                        <FunnelBlockActivityFeed :activity="activity" />
                    </template>

                    <template #activity-log-status-updated="{ activity }">
                        <FunnelBlockStatusActivityFeed :activity="activity" />
                    </template>

                    <template #recruitment-appointment="{ activity }">
                        <RecruitmentAppointmentActivityFeed :activity="activity" />
                    </template>

                    <template #call-appointment="{ activity }">
                        <CallAppointmentActivityFeed :activity="activity" />
                    </template>

                    <template #activity-log-decline-reason-created="{ activity }">
                        <DeclineReasonActivityFeed :activity="activity" :lead="lead" />
                    </template>
                </ActivityFeed>
            </DetailCardTab>
            <DetailCardTab tab="details" label="Details">
                <template #icon>
                    <UserIcon />
                </template>
                <LeadDetails :lead="lead" />
            </DetailCardTab>
        </template>
    </DetailCard>
</template>
