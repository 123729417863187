<script setup lang="ts">
import IncomingCall from '@app/components/calls/overlay/IncomingCall.vue'
import ModalLayout from '@app/components/calls/overlay/ModalLayout.vue'
import OngoingCall from '@app/components/calls/overlay/OngoingCall.vue'
import type { CallDirection, CallRecipient, CallStatus } from '@app/types/calls'
import { computed, ref } from 'vue'

const showCallOverlay = ref(false)
const callDirection = ref<CallDirection>('incoming')
const callStatus = ref<CallStatus>('ringing')
const muted = ref(false)
const isRecorded = ref(false)

const recipient = ref<CallRecipient>({
    phone_number: '+3111111111',
})

const isIncoming = computed(() => ['ringing', 'rejected'].includes(callStatus.value) && callDirection.value === 'incoming')

function endCall() {
    callStatus.value = 'disconnected'
}

const statuses: CallStatus[] = ['ringing', 'in-progress', 'missed', 'disconnected']

function withLead() {
    recipient.value = {
        id: 123456,
        full_name: 'Some really long lead name',
        phone_number: '+3111111112',
    }
}

function withoutLead() {
    recipient.value = {
        phone_number: '+3111111111',
    }
}
</script>

<template>
    <div class="flex gap-2">
        <button @click="withLead">With lead</button>
        <button @click="withoutLead">Without lead</button>
    </div>
    <div class="flex gap-2">
        <button @click="showCallOverlay = true">Show</button>
        <button @click="showCallOverlay = false">Hide</button>
    </div>
    <div class="flex gap-2">
        <button @click="callDirection = 'outgoing'">Outgoing</button>
        <button @click="callDirection = 'incoming'">Incoming</button>
    </div>
    <div class="flex gap-2">
        <button @click="isRecorded = true">With record</button>
        <button @click="isRecorded = false">Without record</button>
    </div>
    <div class="flex gap-2">
        <button v-for="status in statuses" @click="callStatus = status">{{ status }}</button>
    </div>

    <Transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
        <ModalLayout v-if="showCallOverlay" v-slot="{ collapsed }" :collapsible="!isIncoming">
            <IncomingCall v-if="isIncoming" :recipient="recipient" @accept="callStatus = 'in-progress'" @reject="callStatus = 'rejected'" />

            <OngoingCall
                v-else
                :collapsed="collapsed"
                :status="callStatus"
                :recipient="recipient"
                :recorded="isRecorded"
                v-model:muted="muted"
                @dismiss="showCallOverlay = false"
                @disconnect="endCall"
            />
        </ModalLayout>
    </Transition>
</template>
