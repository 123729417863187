import { computed, useTemplateRef, type ModelRef } from 'vue'

export type FileInputModel = File | string | null

export type FileInputProps = {
    limit?: number
    fileTypes?: string[]
    accept?: string
}

export function useFileInput(model: ModelRef<unknown>) {
    const fileName = computed(() => {
        if (!model.value) {
            return undefined
        }

        if (model.value instanceof File) {
            return model.value.name
        }

        if (typeof model.value === 'string') {
            const url = new URL(model.value)

            const pathParts = url.pathname.split('/')

            return pathParts[pathParts.length - 1]
        }
    })

    const fileInput = useTemplateRef<HTMLInputElement>('input:file')

    function selectFile() {
        model.value = fileInput.value?.files?.[0] || null
    }

    function deleteFile() {
        model.value = null
    }

    return {
        fileInput,
        selectFile,
        deleteFile,
        fileName,
    }
}
