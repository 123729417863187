import { computed, type Ref } from 'vue'
import { route } from 'ziggy-js'

const userSettings = ['account settings'] as const

export type UserSettingsGroup = (typeof userSettings)[number]

export function useUserSettingsTabs(currentRoute: Ref<string>, userSettingsGroup: UserSettingsGroup) {
    const accountSettingsTabs = computed(() => [
        {
            name: 'navigation.account_management.profile',
            href: route('user.profile.show'),
            active: currentRoute.value === 'user.profile.show',
        },
        {
            name: 'navigation.account_management.security',
            href: route('user.settings.show'),
            active: currentRoute.value === 'user.settings.show',
        },
    ])

    return {
        accountSettingsTabs,
    }
}
