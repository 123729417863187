<script setup lang="ts">
import CalendarLink from './CalendarLink.vue'
import SearchBar from './SearchBar.vue'
import ToggleSideBar from './ToggleSideBar.vue'
import OpenSideBarButton from './OpenSideBarButton.vue'
import type { User } from '@app/types/shared'
import ProfileNavigation from './ProfileNavigation.vue'
import CallAvailabilityButton from '@app/components/calls/CallAvailabilityButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import { computed } from 'vue'
import { usePage } from '@inertiajs/vue3'
import type { PageProps } from '@app/types/inertia'

defineProps<{ user: User }>()

const { can } = useUserCan()

const enableSearchBar = computed(() => usePage<PageProps>().props.enableSearchBar)
</script>

<template>
    <div class="flex flex-none flex-col">
        <header class="w-full">
            <div class="relative flex h-16 flex-shrink-0 justify-between shadow-sm">
                <OpenSideBarButton />
                <div class="flex flex-1 justify-between gap-4 bg-white px-2 py-3 md:px-6">
                    <ToggleSideBar />
                    <SearchBar v-if="enableSearchBar" />
                    <div class="flex items-center justify-between gap-6">
                        <CallAvailabilityButton v-if="can('call.availability.show')" />
                        <CalendarLink v-if="can('recruitment.availability.show')" class="hidden md:block" />
                        <ProfileNavigation :user="user" />
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>
