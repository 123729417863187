<script setup lang="ts">
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useUserSettingsTabs, type UserSettingsGroup } from '@app/composables/settings/useUserSettingsTabs'

const props = defineProps<{
    group: UserSettingsGroup
}>()

const { currentRoute } = useNavigation()
const { accountSettingsTabs } = useUserSettingsTabs(currentRoute, props.group)
</script>

<template>
    <ContentTabs :items="accountSettingsTabs" bottomBorder />

    <slot></slot>
</template>
