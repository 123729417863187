import { tailwindColors } from '@app/utils/chartSettings'
import { createColorPicker } from '@app/utils/colors'
import { type ChartData, type ChartOptions, Chart } from 'chart.js'
import { computed, ref } from 'vue'
const defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick

const lineColors = [tailwindColors.fonkyBlue, tailwindColors.fonkyOrange, 'green']

export function useLineChartData(data: ChartData<'line'>) {
    const selectedLegendItems = ref(data.datasets.map((item) => item.label))

    const lastValue = computed(() =>
        data.datasets
            .filter((dataset) => selectedLegendItems.value.includes(dataset.label))
            .map((dataset) => dataset.data[dataset.data.length - 1] as number)
            .reduce((acc, number) => acc + number, 0)
    )

    const previousValue = computed(() =>
        data.datasets
            .filter((dataset) => selectedLegendItems.value.includes(dataset.label))
            .map((dataset) => dataset.data[dataset.data.length - 2] as number)
            .reduce((acc, number) => acc + number, 0)
    )

    const change = computed(() => lastValue.value - previousValue.value)

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            x: {
                grid: {
                    display: true,
                },
                border: {
                    color: tailwindColors.zinc300,
                },
                ticks: {
                    display: true,
                    maxRotation: 0,
                    minRotation: 0,
                    color: tailwindColors.zinc400,
                    autoSkip: false,
                    includeBounds: true,
                    font: {
                        size: 12,
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
        plugins: {
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    labelColor(tooltipItem) {
                        return {
                            backgroundColor: tooltipItem.dataset.backgroundColor,
                        }
                    },
                },
            },
            legend: {
                position: 'top',
                align: 'end',
                labels: {
                    font: {
                        size: 12,
                    },
                    boxWidth: 12,
                    boxHeight: 1,
                },
                onClick(event, legendItem, legend) {
                    if (!legendItem.hidden) {
                        selectedLegendItems.value = selectedLegendItems.value.filter((label) => label !== legendItem.text)
                    } else {
                        selectedLegendItems.value = [...selectedLegendItems.value, legendItem.text]
                    }

                    defaultLegendClickHandler(event, legendItem, legend)
                },
            },
        },
        pointBackgroundColor: 'rgba(0, 0, 0, 0)',
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        tension: 0.5,
        pointRadius: 10,
        pointHoverRadius: 6,
    }

    const colorGenerator = createColorPicker(lineColors)

    const chartData = computed(() => {
        return {
            ...data,
            datasets: data.datasets.map((item) => {
                const dataset = {
                    tension: 0.4,
                    ...item,
                }

                if (!dataset.backgroundColor) {
                    const color = colorGenerator.next().value

                    dataset.backgroundColor = color
                    dataset.borderColor = color
                }

                return dataset
            }),
        }
    })

    return {
        chartData,
        options,
        selectedLegendItems,
        lastValue,
        previousValue,
        change,
    }
}
