<script setup lang="ts">
import FileDownload from '@app/components/ui/button/FileDownload.vue'
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import type { Contract } from '@app/types/contracts'
import type { UserPersonalInformation } from '@app/types/shared'
import { isImage } from '@app/utils/file'
import { computed, type PropType } from 'vue'

const props = defineProps({
    contract: {
        type: Object as PropType<Contract>,
        required: true,
    },
    personalInformation: {
        type: Object as PropType<UserPersonalInformation>,
        required: true,
    },
})

const bankCardPhoto = computed(() => props.personalInformation.files.find((file) => file.type === 'bank_card_photo')?.path)
</script>

<template>
    <div class="mt-4 grid grid-cols-2 gap-4">
        <DetailItem :label="$t('attributes.account_holder_name')">
            {{ personalInformation.account_holder_name }}
        </DetailItem>
        <DetailItem :label="$t('attributes.iban')">
            {{ personalInformation.iban }}
        </DetailItem>
        <DetailItem :label="$t('attributes.bsn')">
            {{ personalInformation.bsn }}
        </DetailItem>
        <DetailItem :label="$t('attributes.payroll_tax_credit')">
            {{ personalInformation.payroll_tax_credit ? $t('form.labels.yes') : $t('form.labels.no') }}
        </DetailItem>

        <DetailItem v-if="bankCardPhoto" class="col-span-2" :label="$t('attributes.bank_card_photo')">
            <img
                v-if="contract.status.slug === 'signed' && isImage(bankCardPhoto)"
                :src="bankCardPhoto"
                :alt="$t('attributes.bank_card_photo')"
                class="mt-1 max-w-sm"
            />
            <FileDownload v-else :name="$t('attributes.bank_card_photo')" :path="bankCardPhoto" class="mt-1" />
        </DetailItem>
    </div>
</template>
