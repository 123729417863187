<script setup lang="ts">
import { type FileInputModel, type FileInputProps, useFileInput } from '@app/composables/form/useFileInput'

const model = defineModel<FileInputModel>()

defineProps<FileInputProps>()

const { selectFile, deleteFile, fileName } = useFileInput(model)
</script>

<template>
    <label
        class="cursor-pointer gap-2 rounded-md bg-primary-600 px-4 py-2 text-sm leading-5 text-white transition-colors hover:bg-primary-700 focus:ring-primary-600 disabled:bg-primary-400"
    >
        <slot>{{ $t('form.file.hint_select') }}</slot>
        <input type="file" class="hidden" @change="selectFile" ref="input:file" :accept="accept" />
    </label>

    <div v-if="fileName" class="mt-1 flex items-center gap-2 text-gray-500">
        <span class="text-ellipsis text-sm font-normal leading-5">
            {{ fileName }}
        </span>

        <button @click="deleteFile" class="text-lg">&times;</button>
    </div>
</template>
