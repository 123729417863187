import type { PageProps } from '@app/types/inertia'
import type { Authenticatable } from '@app/types/shared'
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

export function useUserCan() {
    const loggedInUser = usePage<PageProps>().props.user
    const permissions = usePage<PageProps>().props.userPermissions

    const isInternalUser = computed(() => loggedInUser.type === 'web')

    function can(permission: string) {
        return loggedInUser.is_super_admin || permissions.includes(permission)
    }

    function isSuperAdmin(user: Authenticatable) {
        if (typeof user.is_super_admin !== 'undefined') {
            return user.is_super_admin
        }

        return user.roles?.some((role) => role.name === 'information-technology') || false
    }

    function isSameUser(user: Authenticatable) {
        return loggedInUser.id === user.id && loggedInUser.type === user.type
    }

    return { can, isSuperAdmin, isInternalUser, isSameUser, loggedInUser }
}
