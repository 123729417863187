<script setup>
import Label from '@app/components/ui/dropdown/Label.vue'
import { useLocale } from '@app/composables/useLocale'
import { ucfirst } from '@app/utils/transform'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'

const props = defineProps({
    items: {
        type: Object,
        required: true,
    },
    propertyName: {
        type: String,
        required: true,
    },
    label: String,
    showLabel: {
        type: Boolean,
        default: false,
    },
    translateDb: {
        type: Boolean,
        default: true,
    },
    border: {
        type: Boolean,
        default: true,
        required: false,
    },
    modelValue: {
        type: Object,
        default: null,
    },
    form: {
        type: [String, Number, null],
        required: false,
        default: null,
    },
    by: {
        type: String,
        default: 'id',
    },
    hideIfEmpty: {
        type: Boolean,
        default: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['update:modelValue', 'update:form', 'change'])
const { translate } = useLocale()

const modelProxy = computed({
    get() {
        return props.modelValue
    },
    set(newValue) {
        emit('update:modelValue', newValue)
        emit('update:form', newValue[props.by])
        emit('change', newValue)
    },
})
</script>

<template>
    <div v-if="!hideIfEmpty || items.length > 0">
        <Label v-if="showLabel" class="mb-1"> {{ ucfirst(label) }}</Label>
        <Listbox as="div" v-model="modelProxy" :by="by" class="w-full" :disabled="disabled">
            <div class="relative">
                <ListboxButton
                    :class="[
                        border ? 'border border-zinc-300' : 'shadow-none',
                        'relative w-full cursor-default rounded-md py-2 pl-3 pr-10 text-left text-sm shadow-sm focus:border-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500',
                        disabled ? 'bg-gray-100' : 'bg-white',
                    ]"
                    v-slot="{ value }"
                >
                    <span class="block truncate">{{ value ? translate(value[propertyName]) : label }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 sm:ml-3">
                        <ChevronDownIcon class="h-5 w-5 text-zinc-700" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-h-56 w-full min-w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 focus:outline-none sm:w-auto"
                    >
                        <ListboxOption as="template" v-for="item in items" :value="item" v-slot="{ active, selected }">
                            <li
                                :class="[active ? 'bg-primary-600 text-white' : 'text-zinc-900', 'relative cursor-default select-none py-2 pl-3 pr-9']"
                                :data-value="item[by]"
                            >
                                <div class="flex items-center">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate sm:ml-3']">{{
                                        translate(item[propertyName])
                                    }}</span>
                                </div>
                                <span
                                    v-if="selected"
                                    :class="[active ? 'text-white' : 'text-primary-600', 'absolute inset-y-0 right-0 flex items-center pr-4']"
                                >
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>
