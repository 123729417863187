<script setup lang="ts">
import ContractForm from '@app/components/contracts/forms/ContractForm.vue'
import type { Contract, ContractModel, GeneratedFiles } from '@app/types/contracts'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const emit = defineEmits(['content:closed'])
const props = defineProps<{ model: Contract & GeneratedFiles }>()

const form = useForm<ContractModel>({
    branch_id: props.model.branch_id,
    contract_hour_type_id: props.model.contract_hour_type_id,
    duration_months: props.model.duration_months,
    email: props.model.email,
    job_title_id: props.model.job_title.id,
    gross_monthly_salary: props.model.gross_monthly_salary.amount / 100,
    min_hours: props.model.min_hours,
    max_hours: props.model.max_hours,
    sign_by: props.model.sign_by,
    start_date: new Date(props.model.start_date),
    files: props.model.contract_files.map((file) => ({
        id: file.contract_template.id,
        name: file.contract_template.name,
        template: file.content,
    })),
})
</script>

<template>
    <ContractForm :form :action="route('contract.update', props.model.id)" method="put" @content:closed="$emit('content:closed')" />
</template>
