<script setup>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import { add, parse, isEqual, isToday, endOfMonth, endOfWeek, startOfWeek, format, startOfToday, startOfMonth, eachDayOfInterval, isSameMonth } from 'date-fns'
import { computed, onMounted, ref } from 'vue'

let today = startOfToday()
const selectedDay = ref(today)
const selectedMonth = ref(format(today, 'MMMM yyyy'))
const firstDaySelectedMonth = computed(() => parse(selectedMonth.value, 'MMMM yyyy', new Date()))
const firstDayNextMonth = computed(() => add(firstDaySelectedMonth.value, { months: 1 }))
const firstDayPreviousMonth = computed(() => add(firstDaySelectedMonth.value, { months: -1 }))
const days = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(startOfMonth(firstDaySelectedMonth.value), { weekStartsOn: 1 }),
        end: endOfWeek(endOfMonth(firstDaySelectedMonth.value), { weekStartsOn: 1 }),
    })
)

const nextMonth = () => {
    selectedMonth.value = format(firstDayNextMonth.value, 'MMMM yyyy')
}

const previousMonth = () => {
    selectedMonth.value = format(firstDayPreviousMonth.value, 'MMMM yyyy')
}

const emit = defineEmits(['selectedDay:set'])
const setSelectedDay = (day) => {
    selectedDay.value = day
    emit('selectedDay:set', day)
}

onMounted(() => emit('selectedDay:set', startOfToday()))
</script>

<template>
    <div class="flex items-center text-center text-gray-900">
        <button type="button" @click="previousMonth()" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Previous month</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <div class="flex-auto font-semibold">{{ selectedMonth }}</div>
        <button type="button" @click="nextMonth()" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500">
            <span class="sr-only">Next month</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
        </button>
    </div>
    <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>{{ $t('recruitment.calendar.days.mon') }}</div>
        <div>{{ $t('recruitment.calendar.days.tue') }}</div>
        <div>{{ $t('recruitment.calendar.days.wed') }}</div>
        <div>{{ $t('recruitment.calendar.days.thu') }}</div>
        <div>{{ $t('recruitment.calendar.days.fri') }}</div>
        <div>{{ $t('recruitment.calendar.days.sat') }}</div>
        <div>{{ $t('recruitment.calendar.days.sun') }}</div>
    </div>
    <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        <button
            v-for="(day, dayIdx) in days"
            :key="day.toString()"
            type="button"
            :class="[
                'py-1.5 hover:bg-gray-100 focus:z-10',
                isSameMonth(day, firstDaySelectedMonth) ? 'bg-white' : 'bg-gray-50',
                (isEqual(selectedDay, day) || isToday(day)) && 'font-semibold',
                isEqual(selectedDay, day) && 'text-white',
                isToday(day) && !isEqual(selectedDay, day) && 'text-primary-600',
                dayIdx === 0 && 'rounded-tl-lg',
                dayIdx === 6 && 'rounded-tr-lg',
                dayIdx === days.length - 7 && 'rounded-bl-lg',
                dayIdx === days.length - 1 && 'rounded-br-lg',
                !isSameMonth(day, firstDaySelectedMonth) ? 'text-gray-400' : 'text-gray-900',
            ]"
            @click="setSelectedDay(day)"
        >
            <time
                :datetime="format(day, 'dd-MM-yyyy')"
                :class="[
                    'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                    isEqual(selectedDay, day) && isToday(day) && 'bg-primary-600',
                    isEqual(selectedDay, day) && !isToday(day) && 'bg-gray-900',
                ]"
            >
                {{ format(day, 'd') }}
            </time>
        </button>
    </div>
</template>
