<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import ModalTitle from '@app/components/ui/calendar/modal/ModalTitle.vue'
import MultiAvailabilityForm from '@app/components/recruitment/availabilities/MultiAvailabilityForm.vue'
import { computed, inject, watchEffect, type Ref } from 'vue'
import Label from '@app/components/ui/dropdown/Label.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import { useForm, usePage } from '@inertiajs/vue3'
import FieldError from '@app/components/ui/form/FieldError.vue'
import { format, isPast, isToday } from 'date-fns'
import { useLocale } from '@app/composables/useLocale'
import { getDateFromDateTime } from '@app/utils/date'
import { addOrRemoveValueFromObject } from '@app/utils/object'
import { CalendarIcon, ForwardIcon, FunnelIcon, MapIcon, MapPinIcon, PaperClipIcon, UserGroupIcon } from '@heroicons/vue/20/solid'
import { useModelSelect } from '@app/composables/useModelSelect'
import type { User } from '@app/types/shared'
import type { RecruitmentAppointmentHost } from '@app/types/recruitment'
import { route } from 'ziggy-js'
import type { PageProps } from '@app/types/inertia'
import { trans } from 'laravel-vue-i18n'
import { sortBy, uniqBy, isEmpty } from 'lodash'
import { isExcluded } from '@app/utils/openingHours'

defineProps({
    days: {
        type: Array,
        required: true,
    },
    selectedWeek: {
        type: String,
        required: true,
    },
})

const { getLocale } = useLocale()

const emit = defineEmits(['close'])

const hosts = inject('availability:hosts') as Ref<RecruitmentAppointmentHost[]>
const excluded = inject('availability:excluded') as Ref<string[]>

function sortUniqBy<T>(values: T[], sortAttribute = 'id') {
    return sortBy(uniqBy(values, 'id'), sortAttribute)
}

const funnels = computed(() => sortUniqBy(hosts.value.map((host) => host.funnel)))
const selectedFunnel = useModelSelect(funnels, funnels.value[0]?.id)

const funnelBlocks = computed(() => sortUniqBy(hosts.value.filter((host) => host.funnel.id === selectedFunnel.value?.id).map((host) => host.funnel_block)))
const selectedFunnelBlock = useModelSelect(funnelBlocks, funnelBlocks.value[0]?.id)

const branches = computed(() =>
    sortUniqBy(
        hosts.value
            .filter((host) => host.funnel.id === selectedFunnel.value?.id && host.funnel_block.id === selectedFunnelBlock.value?.id)
            .map((host) => host.branch),
        'name'
    )
)
const selectedBranch = useModelSelect(branches, branches.value[0]?.id)

const clientProjects = computed(() =>
    sortUniqBy(
        hosts.value
            .filter(
                (host) =>
                    host.client_project !== null &&
                    host.funnel.id === selectedFunnel.value?.id &&
                    host.funnel_block.id === selectedFunnelBlock.value?.id &&
                    host.branch.id === selectedBranch.value?.id
            )
            .map((host) => host.client_project)
    )
)
const selectedClientProject = useModelSelect(clientProjects, clientProjects.value[0]?.id)

const locationTypes = computed(() =>
    sortUniqBy(
        hosts.value
            .filter(
                (host) =>
                    host.location_type !== null &&
                    host.funnel.id === selectedFunnel.value?.id &&
                    host.funnel_block.id === selectedFunnelBlock.value?.id &&
                    host.branch.id === selectedBranch.value?.id &&
                    host.client_project?.id === selectedClientProject.value?.id
            )
            .map((host) => host.location_type)
    )
)
const selectedLocationType = useModelSelect(locationTypes, locationTypes.value[0]?.id)

const appointmentDuration = computed(() => (selectedFunnelBlock.value?.slug === 'workshop' ? 120 : 60))

const user = inject('availability:user') as Ref<User>
const canSetAvailability = computed(() => hosts.value.length > 0)

const form = useForm({
    branch_id: selectedBranch.value?.id || null,
    funnel_id: selectedFunnel.value?.id || null,
    funnel_block_id: selectedFunnelBlock.value?.id || null,
    client_project_id: null,
    location_type_id: null,
    recruitment_availabilities: {},
    slots: null,
    user_id: user.value.id,
})

const modalTitle = computed(() => {
    let label = trans('recruitment.calendar.add')

    if (user.value.id !== usePage<PageProps>().props.user.id) {
        label = `${label} (${user.value.full_name})`
    }

    return label
})

watchEffect(() => {
    form.branch_id = selectedBranch.value?.id || null
    form.funnel_id = selectedFunnel.value?.id || null
    form.funnel_block_id = selectedFunnelBlock.value?.id || null
    form.slots = selectedFunnelBlock.value?.slots || 0

    if (selectedFunnelBlock.value?.slug === 'workshop') {
        form.client_project_id = selectedClientProject.value?.id || null
        if (selectedFunnel.value?.slug === 'sales-agent') {
            form.location_type_id = selectedLocationType.value?.id || null
        }
    } else {
        form.client_project_id = null
        form.location_type_id = null
    }
})

function sortAvailabilities() {
    form.recruitment_availabilities = Object.fromEntries(Object.entries(form.recruitment_availabilities).sort(([, a], [, b]) => a.day - b.day))
}

const addOrRemoveToSelectedDays = (day) => {
    addOrRemoveValueFromObject(form.recruitment_availabilities, day, { day, slots: [null] })

    if (form.errors.recruitment_availabilities?.[day]) {
        delete form.errors.recruitment_availabilities[day]
    }

    sortAvailabilities()
}

function changeAvailability(day, availabilities) {
    if (availabilities.length === 0) {
        addOrRemoveToSelectedDays(day)

        return
    }

    form.recruitment_availabilities[day] = { day, slots: availabilities }
    sortAvailabilities()
}

const submit = () => {
    form.post(route('recruitment.availability.store'), {
        preserveScroll: true,
        onSuccess: () => emit('close'),
        onError: () => {
            let keys = Object.entries(form.errors)

            keys.forEach(([key, message]) => {
                if (key.startsWith('recruitment_availabilities.')) {
                    const [_, index] = key.split('.')

                    if (!form.errors.recruitment_availabilities) {
                        form.errors.recruitment_availabilities = {}
                    }

                    form.errors.recruitment_availabilities[index] = message
                }
            })
        },
    })
}

function weekdayIsDisabled(day: Date) {
    return isExcluded(day, excluded.value) || (!isToday(day) && isPast(day))
}
</script>

<template>
    <div class="w-[23rem] bg-white p-6 pb-4 md:min-w-[32rem]">
        <div class="flex w-full flex-col items-start gap-3">
            <div class="flex gap-2">
                <div class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primary-100">
                    <CalendarIcon class="h-6 w-6 text-primary-600" aria-hidden="true" />
                </div>
                <ModalTitle :label="modalTitle" />
            </div>
            <div v-if="canSetAvailability" class="flex w-full flex-col gap-6 pl-4 md:pl-12">
                <p class="text-base font-medium leading-6 text-zinc-900">
                    {{ format(days[0], 'dd') }} - {{ getDateFromDateTime(days[days.length - 1]) }} ({{ $t('recruitment.calendar.week') }} {{ selectedWeek }})
                </p>
                <div class="flex flex-col gap-6 border-b border-zinc-200 pb-6 text-base font-medium leading-6 text-zinc-700">
                    <div class="flex flex-col gap-2">
                        <Label :label="$t('form.select') + $t('recruitment.availabilities.funnel')" />
                        <div class="flex items-center space-x-4">
                            <FunnelIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Dropdown
                                class="w-2/3"
                                :border="false"
                                :items="funnels"
                                v-model="selectedFunnel"
                                v-model:form="form.funnel_id"
                                :translateDb="true"
                                propertyName="name"
                            />
                        </div>
                    </div>
                    <div class="flex flex-col gap-2">
                        <Label :label="$t('form.select') + $t('recruitment.availabilities.funnel_block')" />
                        <div class="flex items-center space-x-4">
                            <ForwardIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Dropdown
                                class="w-2/3"
                                :border="false"
                                :items="funnelBlocks"
                                v-model="selectedFunnelBlock"
                                :translateDb="true"
                                propertyName="name"
                            />
                        </div>
                    </div>
                    <div class="flex flex-col gap-2">
                        <Label :label="$t('form.select') + $t('recruitment.availabilities.branch')" />
                        <div class="flex items-center space-x-4">
                            <MapPinIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Dropdown
                                class="w-2/3"
                                :border="false"
                                :items="branches"
                                :translateDb="false"
                                propertyName="name"
                                v-model="selectedBranch"
                                v-model:form="form.branch_id"
                            />
                        </div>
                    </div>
                    <div v-if="selectedFunnelBlock?.slug === 'workshop'" class="flex flex-col gap-2">
                        <Label :label="$t('form.select') + $t('recruitment.availabilities.client_project')" />
                        <div class="flex items-center space-x-4">
                            <PaperClipIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Dropdown
                                class="w-2/3"
                                :border="false"
                                :items="clientProjects"
                                v-model="selectedClientProject"
                                :translateDb="false"
                                propertyName="name"
                            />
                        </div>
                    </div>
                    <div v-if="selectedFunnelBlock?.slug === 'workshop' && selectedFunnel?.slug === 'sales-agent'" class="flex flex-col gap-2">
                        <Label :label="$t('form.select') + $t('recruitment.availabilities.location_type')" />
                        <div class="flex items-center space-x-4">
                            <MapIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Dropdown
                                class="w-2/3"
                                :border="false"
                                :items="locationTypes"
                                v-model="selectedLocationType"
                                :translateDb="false"
                                propertyName="name"
                            />
                        </div>
                    </div>
                    <div class="flex flex-col gap-2">
                        <Label :label="$t('recruitment.availabilities.slots_available')" />
                        <div class="flex items-center space-x-4">
                            <UserGroupIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <div class="mt-1 flex items-baseline gap-5">
                                <input
                                    type="text"
                                    name="slots"
                                    id="slots"
                                    v-model="selectedFunnelBlock.slots"
                                    class="block w-11 rounded-md border-none text-sm font-medium leading-5 text-zinc-700 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                />
                                <span class="text-sm font-medium leading-5 text-zinc-700">{{ $t('recruitment.calendar.slots') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <FieldError :message="form.errors.slots" class="mt-2" />
                <FieldError :message="form.errors.funnel_block_id" class="mt-2" />
                <div class="flex flex-col gap-4 border-b border-zinc-200 pb-6">
                    <Label :label="$t('form.select') + $t('recruitment.availabilities.days')" />
                    <div class="flex gap-3 text-xs leading-6 text-zinc-500">
                        <button
                            v-for="day in days"
                            @click="addOrRemoveToSelectedDays(day)"
                            :class="[
                                form.recruitment_availabilities[day] ? 'bg-primary-500 text-white' : 'bg-zinc-100 text-zinc-500',
                                weekdayIsDisabled(day) && 'opacity-50',
                                'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-xs font-medium uppercase leading-4 tracking-wide',
                            ]"
                            :disabled="weekdayIsDisabled(day)"
                        >
                            {{
                                format(day, 'EEEEEE', {
                                    locale: getLocale(),
                                })
                            }}
                        </button>
                    </div>
                    <div v-for="availability in form.recruitment_availabilities">
                        <MultiAvailabilityForm
                            :key="availability.day"
                            class="mt-2"
                            :availability="availability"
                            @change="changeAvailability"
                            :errors="form.errors"
                            :duration-in-minutes="appointmentDuration"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <FieldError v-if="!canSetAvailability" :message="$t('errors.recruitment.availability.appointment_host_not_set')" class="my-2 px-6" />

    <div class="flex flex-row-reverse gap-4 px-6 py-3">
        <SaveButton v-if="canSetAvailability" :disabled="isEmpty(form.recruitment_availabilities) || form.processing" @click="submit">{{
            $t('recruitment.calendar.add')
        }}</SaveButton>
        <CancelButton @click="emit('close')" />
    </div>
</template>
