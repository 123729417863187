<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormTextArea from '@app/components/ui/form/FormTextArea.vue'
import Label from '@app/components/ui/form/Label.vue'
import type { NoteForm } from '@app/types/note'
import { useForm } from '@inertiajs/vue3'
import { inject } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    model: {
        type: Object,
        required: true,
    },
})

const activeButton = inject<any>('activeButton')

const form = useForm<NoteForm>({
    notable_id: props.model.id,
    notable_type: 'recruitment_lead',
    note: '',
})

const submitNote = () => {
    form.post(route('note.store'), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            activeButton.value = null
        },
    })
}

const cancelNote = () => {
    form.cancel()
    activeButton.value = null
}
</script>

<template>
    <div class="mx-auto w-full">
        <Form :form="form" @submit="submitNote" id="note_form">
            <Label for="comment">{{ $t('recruitment.leads.note.description') }}</Label>
            <div class="mt-1">
                <FormTextArea id="note" v-model="form.note" :rows="6" autocomplete="off" autofocus />
            </div>
            <FieldError :message="form.errors['note']" class="mt-2" />
            <div class="mt-4 flex place-content-end space-x-2">
                <CancelButton @click="cancelNote" />
                <SaveButton :disabled="form.processing" id="note_save_button">{{ $t('recruitment.leads.note.save') }}</SaveButton>
            </div>
        </Form>
    </div>
</template>
