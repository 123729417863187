<script setup lang="ts">
import { useCurrencyInput, ValueScaling } from 'vue-currency-input'
import Input from './Input.vue'

const props = defineProps({
    currency: {
        type: String,
        default: 'EUR',
    },
})

defineModel<number | null>()

const { inputRef } = useCurrencyInput({
    valueScaling: ValueScaling.precision,
    currency: props.currency,
})
</script>

<template>
    <Input ref="inputRef" />
</template>
