<script setup lang="ts">
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import { computed, ref, shallowRef, watch } from 'vue'
import type { Lead } from '@app/types/recruitment'
import { useLeadOutcomes } from '@app/composables/recruitment/useLeadOutcomes'

const props = defineProps<{
    model: Lead
}>()

const { outcomes, selectedOutcome, getOutcomeComponentBySlug } = useLeadOutcomes(props.model)

const activeOutcome = shallowRef()

watch(selectedOutcome, () => {
    if (selectedOutcome.value) {
        activeOutcome.value = getOutcomeComponentBySlug(selectedOutcome.value.slug)
    }
})

const showOutcome = ref(true)
const showDropdown = computed(() => props.model.is_contactable && !props.model.is_status_final && outcomes.value.length > 0)

const emit = defineEmits(['content:closed'])
const handleClosedContent = () => {
    emit('content:closed')
    showOutcome.value = false
}
</script>

<template>
    <div>
        <Dropdown
            v-if="showDropdown && showOutcome"
            class="mx-auto max-w-sm sm:px-4"
            :items="outcomes"
            propertyName="name"
            :label="$t('recruitment.leads.outcomes.label')"
            :showLabel="false"
            :translateDb="true"
            by="slug"
            v-model="selectedOutcome"
        />
        <div class="w-full items-center justify-between">
            <Transition name="fade" mode="out-in">
                <component v-if="showOutcome" :is="activeOutcome" :lead="model" :outcome="selectedOutcome" @content:closed="handleClosedContent()" />
            </Transition>
        </div>
    </div>
</template>
