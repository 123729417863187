import { callAppointmentFilters, callFilters, recruitmentAppointmentFilters } from '@app/utils/recruitment/filters'
import { getLeadsLink, getLeadsStoredFilterName } from '@app/utils/recruitment/lead'
import {
    BookOpenIcon,
    CogIcon,
    DocumentMagnifyingGlassIcon,
    HomeIcon,
    PencilSquareIcon,
    ShieldCheckIcon,
    ShoppingBagIcon,
    UserGroupIcon,
    UserPlusIcon,
} from '@heroicons/vue/24/outline'
import { router, usePage } from '@inertiajs/vue3'
import { computed, ref } from 'vue'
import { route } from 'ziggy-js'
import { useStoredFilters } from '../table/useStoredFilters'
import { useUserCan } from '../useUserCan'

export function useNavigation() {
    const { can } = useUserCan()
    const user = usePage().props.user
    const { getStoredFilters } = useStoredFilters()
    const funnels = usePage().props.funnels

    const getLink = (name) => {
        switch (name) {
            case 'home':
                return route('home')
            case 'recruitment-call':
                return route('recruitment.call.index', getStoredFilters('recruitment.call.index', callFilters))
            case 'recruitment-appointment-call':
                return route('recruitment.appointment.call.index', getStoredFilters('recruitment.appointment.call.index', callAppointmentFilters))
            case 'recruitment-appointment-recruitment':
                return route(
                    'recruitment.appointment.recruitment.index',
                    getStoredFilters('recruitment.appointment.recruitment.index', recruitmentAppointmentFilters)
                )
        }
    }

    function getAppointmentsLink() {
        if (can('recruitment.call-appointment.index')) {
            return getLink('recruitment-appointment-call')
        }

        return getLink('recruitment-appointment-recruitment')
    }

    function isCurrentRoute(route) {
        return currentRoute.value === route
    }

    function routeStartsWith(path) {
        return currentRoute.value?.startsWith(path) || false
    }

    function routeHasParts(parts) {
        return currentRoute.value.split('.').length === parts
    }

    function getLeadsLinkWithRestoredFilters(funnel, funnelBlock) {
        return getLeadsLink(funnel, funnelBlock, getStoredFilters(getLeadsStoredFilterName(funnel, funnelBlock)))
    }

    function getFunnelNavTab(funnel) {
        const firstFunnelBlock = getFunnelBlockTabs(funnel).find((funnelBlockTab) => can(funnelBlockTab.permission))

        if (!firstFunnelBlock) return null

        return {
            id: funnel.slug,
            name: funnel.name,
            href: firstFunnelBlock.href,
            active: getIsSecondaryActive('recruitment.lead.index', { funnel: funnel.slug }),
            show: can(firstFunnelBlock.permission),
        }
    }

    const sideBarNavigation = computed(() => [
        {
            id: 'home',
            name: 'navigation.home',
            href: route('home'),
            icon: HomeIcon,
            active: isCurrentRoute('home'),
        },
        {
            id: 'recruitment',
            name: 'navigation.recruitment.header',
            href: getLink('recruitment', 'lead'),
            icon: UserPlusIcon,
            active: routeStartsWith('recruitment'),
            items: [
                ...funnels.map(getFunnelNavTab).filter((item) => item !== null),
                {
                    id: 'recruitment.availability',
                    name: 'navigation.recruitment.availability',
                    href: route('recruitment.availability.show'),
                    active: isCurrentRoute('recruitment.availability.show'),
                    show: can('recruitment.availability.show'),
                },
                {
                    id: 'recruitment.appointments',
                    name: 'navigation.recruitment.appointment.header',
                    href: getAppointmentsLink(),
                    active: routeStartsWith('recruitment.appointment.'),
                    show: can('recruitment.call-appointment.index') || can('recruitment.recruitment-appointment.index'),
                },
                {
                    id: 'recruitment.whatsapp',
                    name: 'navigation.recruitment.whatsapp',
                    href: route('recruitment.whatsapp.index'),
                    active: isCurrentRoute('recruitment.whatsapp.index'),
                    show: can('recruitment.whatsapp.index'),
                },
                {
                    id: 'recruitment.calls',
                    name: 'navigation.recruitment.call',
                    href: getLink('recruitment-call'),
                    active: isCurrentRoute('recruitment.call.index'),
                    show: can('recruitment.call.index'),
                },
                {
                    id: 'recruitment.incoming',
                    name: 'navigation.recruitment.incoming',
                    href: route('recruitment.incoming.index'),
                    active: isCurrentRoute('recruitment.incoming.index'),
                    show: can('recruitment.incoming.index'),
                },
                {
                    name: 'navigation.users.recruitment-selection',
                    href: route('user.client.index'),
                    active: routeStartsWith('user.client.'),
                    show: can('user.client.index'),
                },
            ],
        },
        {
            id: 'shifts',
            name: 'navigation.shifts.header',
            icon: PencilSquareIcon,
            active: routeStartsWith('shifts'),
            show: can('shifts.shift.index'),
            items: [
                {
                    id: 'shifts.shifts',
                    name: 'navigation.shifts.shifts',
                    href: route('shifts.shift.index'),
                    active: isCurrentRoute('shifts.shift.index'),
                    show: can('shifts.shift.index'),
                },
            ],
        },
        {
            id: 'orders',
            name: 'navigation.orders.header',
            icon: ShoppingBagIcon,
            active: routeStartsWith('orders'),
            show: can('orders.order.index'),
            items: [
                {
                    name: 'navigation.orders.orders',
                    href: route('orders.order.index'),
                    active: isCurrentRoute('orders.order.index'),
                    show: can('orders.order.index'),
                },
            ],
        },
        {
            id: 'users',
            name: 'navigation.users.users',
            href: route('user.index'),
            icon: UserGroupIcon,
            active: isCurrentRoute('user.index') || isCurrentRoute('user.recruitment-selection.index'),
            show: can('user.index'),
        },
        {
            id: 'contracts',
            name: 'navigation.contracts',
            href: route('contract.index', { ...getStoredFilters('contract.index.new'), scope: 'new' }),
            icon: DocumentMagnifyingGlassIcon,
            active: isCurrentRoute('contract.index'),
            show: can('contract.index'),
        },
        {
            id: 'settings',
            name: 'navigation.settings.header',
            icon: CogIcon,
            show: can('settings.index'),
            active: routeStartsWith('settings'),
            items: [
                {
                    id: 'settings.general',
                    name: 'navigation.settings.general',
                    href: route('settings.role.index'),
                    active: routeStartsWith('settings') && routeHasParts(3),
                    show: can('settings.general'),
                },
                {
                    id: 'settings.recruitment',
                    name: 'navigation.settings.recruitment',
                    href: route('settings.recruitment.channel.index'),
                    active: routeStartsWith('settings.recruitment'),
                    show: can('settings.recruitment'),
                },
                {
                    id: 'settings.products',
                    name: 'navigation.settings.products',
                    href: route('settings.products.product.index'),
                    active: routeStartsWith('settings.products'),
                    show: can('products.product.index'),
                },
                {
                    id: 'settings.shifts',
                    name: 'navigation.settings.shifts',
                    href: route('settings.shifts.external-project.index'),
                    active: routeStartsWith('settings.shifts.'),
                    show: can('shifts.external-project.index'),
                },
                {
                    id: 'settings.templates',
                    name: 'navigation.settings.templates',
                    href: route('settings.template.whatsapp.index'),
                    active: routeStartsWith('settings.template'),
                    show: can('settings.template'),
                },
            ],
        },
        {
            name: 'navigation.knowledge',
            href: 'https://sites.google.com/fonky.nl/kennisbank',
            icon: BookOpenIcon,
            active: false,
            external_link: true,
            show: user.email.includes('@fonky.nl'),
        },
        {
            name: 'navigation.monitor',
            href: route('pulse'),
            icon: ShieldCheckIcon,
            active: false,
            external_link: true,
            show: can('pulse.view'),
        },
    ])

    const currentRoute = ref(route().current())
    const currentParams = ref(route().params)
    router.on('navigate', () => {
        currentParams.value = route().params
        currentRoute.value = route().current()
    })

    const getIsSecondaryActive = (routeName, params = null) => {
        if (!params) {
            return currentRoute.value === routeName
        }

        let result = currentRoute.value === routeName
        Object.keys(params).forEach((key) => {
            if (params[key] !== currentParams.value[key]) {
                result = false
            }
        })

        return result
    }

    const activeNavItem = computed(() => sideBarNavigation.value.find((item) => item.active))

    function getLeadNavItem(funnel, funnelBlock) {
        return {
            id: funnelBlock.slug,
            name: funnelBlock.name,
            href: getLeadsLinkWithRestoredFilters(funnel.slug, funnelBlock.slug),
            active: getIsSecondaryActive('recruitment.lead.index', {
                funnel: funnel.slug,
                funnelBlock: funnelBlock.slug,
            }),
            permission: `recruitment.lead.${funnel.slug}.${funnelBlock.slug}.index`,
        }
    }

    function getFunnelBlockTabs(funnel) {
        return funnel.blocks.map((funnelBlock) => getLeadNavItem(funnel, funnelBlock))
    }

    const redirectToLead = (lead) => {
        router.get(
            route('recruitment.lead.index', {
                funnelBlock: lead.funnel_block.slug,
                funnel: lead.funnel.slug,
                selected: lead.id,
            })
        )
    }

    const getRedirectToLeadHref = (lead) => {
        return route('recruitment.lead.index', {
            funnel: lead.funnel.slug,
            funnelBlock: lead.funnel_block?.slug || lead.funnelBlock?.slug,
            selected: lead.id,
            name: lead.full_name,
        })
    }

    return {
        getLink,
        sideBarNavigation,
        redirectToLead,
        getFunnelBlockTabs,
        getRedirectToLeadHref,
        currentRoute,
        activeNavItem,
    }
}
