<script setup>
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import { useLocale } from '@app/composables/useLocale'
import { useModelSelect } from '@app/composables/useModelSelect'
import { useForm, usePage } from '@inertiajs/vue3'
import VueDatePicker from '@vuepic/vue-datepicker'
import { formatISO } from 'date-fns'
import { computed, inject, ref } from 'vue'

const props = defineProps({
    model: {
        type: Object,
        required: true,
    },
})

const branches = computed(() => usePage().props.funnelBranches || usePage().props.branches)

const { getLocale } = useLocale()
const selectedBranch = useModelSelect(branches, props.model.branch.id)

const form = useForm({
    first_name: props.model.first_name ? props.model.first_name : null,
    last_name: props.model ? props.model.last_name : null,
    phone_number: props.model ? props.model.phone_number : null,
    email: props.model ? props.model.email : null,
    date_of_birth: props.model.date_of_birth ? new Date(props.model.date_of_birth) : null,
    branch_id: props.model.branch.id,
})

const activeButton = inject('activeButton')

const dateInputOptions = ref({
    format: 'dd-MM-yyyy',
})

const update = () => {
    form.transform((data) => ({
        ...data,
        date_of_birth: data.date_of_birth ? formatISO(data.date_of_birth, { representation: 'date' }) : null,
    })).put(route('recruitment.lead.update', props.model.id), {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
            activeButton.value = null
        },
    })
}
</script>

<template>
    <Form :form="form" @submit="update" id="edit_form">
        <FormSection :title="$t('recruitment.leads.edit.description')">
            <FormField class="col-span-12 sm:col-span-6" prop="first_name" :label="$t('attributes.first_name')">
                <Input id="first_name" v-model="form.first_name" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="last_name" :label="$t('attributes.last_name')">
                <Input id="last_name" v-model="form.last_name" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="phone_number" :label="$t('attributes.phone_number')">
                <Input id="phone_number" v-model="form.phone_number" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="email" :label="$t('attributes.email')">
                <Input id="email" type="email" v-model="form.email" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="date_of_birth" :label="$t('attributes.date_of_birth')">
                <VueDatePicker
                    id="date_of_birth"
                    v-model="form.date_of_birth"
                    :locale="getLocale().code"
                    :auto-position="false"
                    auto-apply
                    :enable-time-picker="false"
                    text-input
                    :text-input-options="dateInputOptions"
                    format="dd-MM-yyyy"
                />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="branch_id" :label="$t('attributes.branch_id')">
                <Dropdown
                    id="branch_dropdown"
                    :items="branches"
                    propertyName="name"
                    :label="$t('attributes.branch_id')"
                    v-model="selectedBranch"
                    v-model:form="form.branch_id"
                    :translateDb="false"
                />
            </FormField>
        </FormSection>

        <FormActions>
            <CancelButton @click="activeButton = null" />
            <SaveButton :disabled="form.processing" id="edit_save_button" />
        </FormActions>
    </Form>
</template>
