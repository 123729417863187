<script setup>
import Weekday from '@app/components/ui/calendar/Weekday.vue'
import { useLocale } from '@app/composables/useLocale'
import { ucfirst } from '@app/utils/transform'
import { format, isToday } from 'date-fns'

const props = defineProps({
    days: {
        type: Array,
        required: true,
    },
})

const { getLocale } = useLocale()
</script>

<template>
    <Weekday
        v-for="day in days"
        :key="day.toString()"
        :active="isToday(day)"
        :weekday="ucfirst(format(day, 'EEEE', { locale: getLocale() }))"
        :day="format(day, 'd')"
    />
</template>
