<script setup lang="ts">
import GraphCard from '@app/components/dashboard/GraphCard.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import type { PageProps } from '@app/types/inertia'
import { chartDefaults } from '@app/utils/chartSettings'
import { router, usePage } from '@inertiajs/vue3'
import type { ChartData } from 'chart.js'
import { computed, onMounted } from 'vue'

type BranchKpi = {
    name: string
    kpi: Record<
        string,
        {
            name: string
            range: string
            data: ChartData
        }
    >
}

type DashboardPageProps = {
    kpi: Array<{ slug: string; name: string }>
    graphs?: Array<BranchKpi>
    selectedKpi: string[]
}

const props = defineProps<DashboardPageProps>()

onMounted(() => {
    router.reload({ only: ['graphs'] })
})

chartDefaults()

const branches = computed(() => [
    {
        slug: 'all',
        name: 'dashboard.filters.locations',
    },
    ...usePage<PageProps>().props.branches,
])

// const tabs = computed(() => [
//     {
//         name: 'KPI report',
//         href: '',
//         active: true,
//     },
//     {
//         name: 'Scoreboard',
//         href: '',
//         active: false,
//     },
// ])

const graphRows = computed(() => {
    switch (props.selectedKpi.length) {
        case 1:
            return 'col-span-1'
        case props.kpi.length:
        default:
            return 'col-span-2'
    }
})
</script>

<template>
    <!-- <ContentTabs :items="tabs" bottomBorder /> -->
    <div class="flex items-center justify-end gap-2 pb-4">
        <DropdownMultiFilter :items="branches" slug="branch" by="slug" class="!min-w-40" all-label="dashboard.filters.locations" />
        <DropdownMultiFilter :items="kpi" slug="kpi" by="slug" class="!min-w-40" all-label="dashboard.filters.kpi" />
    </div>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
        <div v-if="graphs" v-for="graph in graphs" :class="graphRows">
            <div class="mb-4 text-lg font-medium leading-6 text-zinc-800">{{ $t(graph.name) }}</div>
            <div class="flex flex-col gap-4 lg:flex-row">
                <GraphCard v-for="kpi in graph.kpi" :title="kpi.name" :range="kpi.range" :data="kpi.data" :key="`${graph.name}-${kpi.name}`" />
            </div>
        </div>
    </div>
    <div class="flex flex-col flex-wrap gap-4">
        <!-- <div class="flex justify-between gap-4">
            <LargeGraph title="KPI card" range="Last month" :lineColor="tailwindColors.fonkyBlue" :dotColor="tailwindColors.fonkyBlue" />
        </div> -->
    </div>
</template>

<style lang="css">
/** @see https://github.com/chartjs/Chart.js/issues/11005 */
canvas {
    width: 100% !important;
    height: 100% !important;
}
</style>
