import type { User } from '@app/types/shared'

export function useAvatar() {
    function getUserAvatar(user: User, rounded = true) {
        return user.profile_photo_url || getRandomAvatar(user.full_name, rounded)
    }

    function getRandomAvatar(name: string, rounded = true) {
        return `https://ui-avatars.com/api/?rounded=${rounded}&background=random&name=${name}`
    }

    return { getUserAvatar, getRandomAvatar }
}
