<script lang="ts" setup>
import { computed, provide, ref, toRefs } from 'vue'
import Header from '@app/components/ui/calendar/Header.vue'
import Weekdays from '@app/components/ui/calendar/Weekdays.vue'
import Event from '@app/components/ui/calendar/Event.vue'
import EventForm from '@app/components/recruitment/availabilities/EventForm.vue'
import ModalLayout from '@app/components/ui/modal/ModalLayout.vue'
import { isWeekend, setHours, endOfWeek, startOfWeek, format, startOfToday, eachDayOfInterval, eachHourOfInterval } from 'date-fns'
import type { RecruitmentAppointmentHost, RecruitmentAvailability } from '@app/types/recruitment'
import type { User } from '@app/types/shared'
import {
    getAppointmentColors,
    getDayClass,
    getEventTitle,
    getSlotsTakenStyled,
    getThisWeeksAvailabilities,
    getTimeStyle,
} from '@app/utils/recruitment/recruitment-availability'
import { isExcluded } from '@app/utils/openingHours'

const props = defineProps<{
    users: Array<User>
    availabilities: Array<RecruitmentAvailability>
    appointmentHosts: Array<RecruitmentAppointmentHost[]>
    selectedUser: User
    excludedDates: string[]
}>()

const { appointmentHosts, selectedUser, excludedDates } = toRefs(props)

provide('availability:excluded', excludedDates)
provide('availability:user', selectedUser)
provide('availability:hosts', appointmentHosts)

const selectedDay = ref(startOfToday())
const days = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)
const weekdays = computed(() => days.value.filter((day) => !isWeekend(day)))

const STARTING_HOUR = 9

const getHours = (day: Date) => {
    return eachHourOfInterval({
        start: setHours(day, STARTING_HOUR),
        end: setHours(day, 22),
    })
}

const availabilitiesThisWeek = computed(() => props.availabilities.filter((availability) => getThisWeeksAvailabilities(availability, selectedDay.value)))

const openedAvailability = ref<RecruitmentAvailability | null>()
const openEventModal = (availability: RecruitmentAvailability) => {
    openedAvailability.value = availability
}

const closeEventModal = () => (openedAvailability.value = null)
</script>

<template>
    <div class="flex h-full flex-col">
        <Header :days="weekdays" v-model="selectedDay" :users="users" />
        <div
            ref="container"
            class="isolate flex flex-auto flex-col overflow-y-auto bg-white scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 lg:overflow-x-hidden"
        >
            <div style="width: 165%" class="flex max-w-none flex-none flex-col lg:max-w-full">
                <!-- Weekdays -->
                <div class="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5">
                    <div class="-mr-px grid grid-cols-5 divide-x divide-gray-100 border-r border-gray-100 text-xs leading-4 text-gray-500">
                        <div class="col-end-1 w-14" />
                        <Weekdays :days="weekdays" />
                    </div>
                </div>
                <!-- Hours and events -->
                <div class="flex">
                    <div class="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
                    <div class="grid flex-auto grid-cols-1 grid-rows-1">
                        <!-- Horizontal lines -->
                        <div
                            class="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                            style="grid-template-rows: repeat(calc(14 * 4), minmax(1rem, 1fr))"
                        >
                            <div class="row-end-1 h-7" />
                            <div v-for="hour in getHours(selectedDay)" class="row-span-4">
                                <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    {{ format(hour, 'HH:mm') }}
                                </div>
                            </div>
                        </div>

                        <!-- Vertical lines -->
                        <div class="col-start-1 col-end-2 row-start-1 grid grid-cols-5 grid-rows-1 divide-x divide-gray-100">
                            <div v-for="day in weekdays" :class="{ 'bg-gray-100': isExcluded(day, excludedDates) }"></div>
                        </div>

                        <!-- Events -->
                        <div
                            class="col-start-1 col-end-2 row-start-1 grid w-full grid-cols-5"
                            style="
                                grid-template-rows:
                                    1.75rem repeat(calc(14 * 4), minmax(0, 1fr))
                                    auto;
                            "
                        >
                            <div
                                v-for="availability in availabilitiesThisWeek"
                                :key="availability.id"
                                :class="getDayClass(availability.from)"
                                :style="getTimeStyle(availability, STARTING_HOUR)"
                            >
                                <Event
                                    :title="getEventTitle(availability)"
                                    :from="availability.from"
                                    :to="availability.to"
                                    :content="getSlotsTakenStyled(availability)"
                                    :colors="getAppointmentColors(availability)"
                                    @click="openEventModal(availability)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalLayout :show="!!openedAvailability" @close="closeEventModal">
            <EventForm v-if="openedAvailability" :availability="openedAvailability" @close="closeEventModal" :key="openedAvailability.id" />
        </ModalLayout>
    </div>
</template>
