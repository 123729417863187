<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { type ShallowRef, inject, type Component } from 'vue'
import { DocumentIcon } from '@heroicons/vue/24/solid'
import { useUserCan } from '@app/composables/useUserCan'
import LeadNoteForm from '@app/components/recruitment/leads/forms/LeadNoteForm.vue'

const activeButton = inject<ShallowRef<Component>>('activeButton')
const { can } = useUserCan()
</script>

<template>
    <RoundButton v-if="can('note.store')" :label="$t('recruitment.leads.card.actions.note')" @click="activeButton = LeadNoteForm">
        <DocumentIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
