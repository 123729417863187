<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import { Link, router } from '@inertiajs/vue3'
import { useUserCan } from '@app/composables/useUserCan'
import { route } from 'ziggy-js'
import Settings from '@app/layouts/Settings.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import type { JobTitle, ResourceCollection, WorkType } from '@app/types/shared'
import { useLocale } from '@app/composables/useLocale'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'

defineProps<{
    jobTitles: ResourceCollection<JobTitle>
    workTypes: WorkType[]
}>()

const { can } = useUserCan()

const columns = [
    {
        type: 'string',
        header: 'ID',
        properties: { name: 'id' },
        sort: 'id',
    },
    {
        type: 'string',
        header: 'attributes.name',
        properties: { name: 'name', translate: true },
        sort: 'name',
    },
    {
        name: 'work_type',
        header: 'attributes.work_type',
    },
    {
        name: 'actions',
        header: 'actions.header',
        show: can('job-title.store') || can('job-title.destroy'),
    },
]

const params = route().params

const { dbTranslate } = useLocale()

const deleteJobTitle = (id: number) => {
    router.delete(route('settings.job-title.delete', { id: id }))
}
</script>

<template>
    <Settings group="general">
        <div class="mb-4 md:flex md:items-center md:justify-between">
            <div class="flex place-items-center">
                <Button v-if="can('job-title.store')" :as="Link" :href="route('settings.job-title.create')"> {{ $t('actions.create') }} </Button>
            </div>
        </div>
        <DataTable class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500" :items="jobTitles" :columns="columns">
            <template #filter>
                <FilterSection :enable-search="true" :text-search-param="params.name">
                    <DropdownMultiFilter :items="workTypes" :label="$t('attributes.work_type')" slug="work_type" by="slug" />
                    <ResetFilterButton class="mt-2" :href="route('settings.job-title.index')" :label="$t('buttons.clear')" />
                </FilterSection>
            </template>

            <template v-slot:column.work_type="{ item }">
                {{ item.work_type ? dbTranslate(item.work_type.name) : '' }}
            </template>

            <template v-slot:column.actions="{ item }">
                <Link :v-if="can('job-title.store')" class="mr-2 text-primary-600" :href="route('settings.job-title.edit', { slug: item.slug })">
                    {{ $t('actions.update') }}
                </Link>
                <button :v-if="can('job-title.destroy')" type="button" class="text-primary-600" @click="deleteJobTitle(item.id)">
                    {{ $t('actions.delete') }}
                </button>
            </template>
        </DataTable>
    </Settings>
</template>
