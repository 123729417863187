<script setup lang="ts">
import { useFileInput, type FileInputModel, type FileInputProps } from '@app/composables/form/useFileInput'
import { isImage } from '@app/utils/file'
import { computed, onUnmounted, ref } from 'vue'

const model = defineModel<FileInputModel>()

type Props = FileInputProps & { label?: string }

const props = defineProps<Props>()

const { selectFile, deleteFile, fileName, fileInput } = useFileInput(model)

const fileTypesFormatted = computed(() => {
    const fileTypes = props.fileTypes || []

    return fileTypes.map((type) => type.toUpperCase()).join(', ')
})

const limitFormatted = computed(() => {
    if (props.limit < 1024) {
        return `${props.limit}KB`
    }

    return `${Math.round(props.limit / 1024)}MB`
})

const canPreview = computed(() => isImage(model.value))

const filePreview = computed(() => {
    if (!canPreview.value) {
        return false
    }

    if (model.value instanceof File) {
        return URL.createObjectURL(model.value)
    }

    return model.value
})

function dropFile(event: DragEvent) {
    if (!model.value && fileInput.value && event.dataTransfer?.files) {
        fileInput.value.files = event.dataTransfer.files
        model.value = event.dataTransfer.files[0]
    }
}

onUnmounted(() => {
    if (filePreview.value) {
        URL.revokeObjectURL(filePreview.value)
    }
})
</script>

<template>
    <div>
        <label class="mb-2 block text-sm font-medium leading-5 text-gray-700" v-if="label">{{ label }}</label>
        <div class="rounded-md border-2 border-dashed border-gray-300 p-6" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
            <img v-if="canPreview && filePreview" :src="filePreview" :alt="fileName" class="input-image" />
            <div v-else-if="model" class="py-4 text-center font-bold text-gray-300">
                {{ $t('form.file.no_preview') }}
            </div>

            <div class="flex flex-col items-center gap-2" v-else>
                <img src="@assets/icons/icon-upload.svg" alt="" class="pointer-events-none self-center" />

                <p class="self-center text-center text-sm font-medium leading-5">
                    <label class="cursor-pointer text-primary-600">
                        {{ $t('form.file.hint_select') }}
                        <input type="file" class="hidden" ref="input:file" @change="selectFile" :accept="accept" />
                    </label>
                    <span class="text-gray-600">{{ $t('form.file.hint_dnd') }}</span>
                </p>

                <p class="text-xs text-gray-500">
                    {{ fileTypesFormatted }}
                    {{ $t('form.file.limit', { limit: limitFormatted }) }}
                </p>
            </div>
        </div>

        <div v-if="fileName" class="mt-2 text-gray-500">
            <span class="text-sm font-normal leading-5">
                {{ fileName }}

                <button @click="deleteFile" class="ml-2 text-lg">&times;</button>
            </span>
        </div>
    </div>
</template>
