<script setup lang="ts">
import ContractFooter from '@app/components/contracts/sign/ContractFooter.vue'
import ContractHeader from '@app/components/contracts/sign/ContractHeader.vue'
import ContractSignForm from '@app/components/contracts/sign/ContractSignForm.vue'
import Button from '@app/components/ui/button/Button.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import SignaturePad from '@app/components/ui/form/SignaturePad.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useFormWizard } from '@app/composables/form/useFormWizard'
import Guest from '@app/layouts/Guest.vue'
import { convertPathToDataURL, dataURLToFile } from '@app/utils/image'
import { useForm } from '@inertiajs/vue3'
import { onMounted, watch } from 'vue'

defineOptions({
    layout: Guest,
})

type SignatureModel = {
    user_signature: string | null
}

const props = defineProps<{
    steps: FormStep[]
    text: string
    user_signature: string | null
    employee_first_name: string
    employee_full_name: string
}>()

const { currentStep, navigateToPreviousStep } = useFormWizard(props.steps)

const form = useForm<SignatureModel>({
    user_signature: null,
})

async function restoreSavedSignature() {
    const dataUrl = await convertPathToDataURL(props.user_signature)

    form.user_signature = dataUrl
}

onMounted(restoreSavedSignature)
watch(currentStep, restoreSavedSignature)

async function handleSubmit() {
    const now = Date.now()
    const signatureFile = await dataURLToFile(form.user_signature, `signature_${now}.png`)

    form.transform((data) => {
        return {
            ...data,
            user_signature: signatureFile,
        }
    }).post('', {
        preserveScroll: (page) => Object.keys(page.props.errors).length > 0,
        preserveState: (page) => Object.keys(page.props.errors).length > 0,
    })
}
</script>

<template>
    <ContractSignForm :steps="steps">
        <Form :form="form" @submit="handleSubmit">
            <ContractHeader :name="employee_first_name" />

            <div v-html="text"></div>

            <ContractFooter :employee-name="employee_full_name">
                <FormField prop="signature" class="mt-3 max-w-xs">
                    <SignaturePad v-model="form.user_signature" :label="$t('form.labels.signature')" />
                </FormField>
            </ContractFooter>

            <FormActions>
                <Button color="white" type="button" @click="navigateToPreviousStep">{{ $t('buttons.back') }}</Button>
                <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </ContractSignForm>
</template>
