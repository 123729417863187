<script setup lang="ts">
import { type PropType } from 'vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import { UserIcon } from '@heroicons/vue/20/solid'
import { useLocale } from '@app/composables/useLocale'
import { useColor } from '@app/composables/useColor'
import Badge from '@app/components/ui/badge/Badge.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import type { Lead } from '@app/types/recruitment'
import { useUserCan } from '@app/composables/useUserCan'
import SelectLeadOutcome from './actions/SelectLeadOutcome.vue'
import LeadClientDetails from './LeadClientDetails.vue'
import { prettyPhone } from '@app/utils/transform'

const { dbTranslate } = useLocale()
const { getFunnelBlockStatusColor } = useColor()
const { isInternalUser } = useUserCan()

defineProps({
    lead: {
        type: Object as PropType<Lead>,
        required: true,
    },
})

const emit = defineEmits(['close', 'reload'])
</script>

<template>
    <DetailCard :model="lead" :dismissable="true" @close="emit('close')">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle>{{ lead.full_name }}</DetailCardTitle>
            <div class="flex flex-col items-center gap-4">
                <p class="text-center">{{ prettyPhone(lead.phone_number) }}</p>

                <Badge v-if="isInternalUser" color="gray">{{ lead.client_information?.client.name }}</Badge>

                <DetailCardLabel :label="$t('attributes.status') + ' :'">
                    <div>
                        <Badge
                            size="lg"
                            class="leading-6"
                            :label="dbTranslate(lead.funnel_block_status.name)"
                            :color="getFunnelBlockStatusColor(lead.funnel_block_status.slug)"
                        />
                    </div>
                </DetailCardLabel>
            </div>
        </div>
        <template #actions>
            <SelectLeadOutcome :lead="lead" />
        </template>
        <template #tabs>
            <DetailCardTab tab="details" label="Details">
                <template #icon>
                    <UserIcon />
                </template>
                <LeadClientDetails :lead="lead" />
            </DetailCardTab>
        </template>
    </DetailCard>
</template>
