<script setup lang="ts">
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import type { Lead } from '@app/types/recruitment'
import { getZonedDate } from '@app/utils/date'
import { trans } from 'laravel-vue-i18n'
import { computed, type PropType } from 'vue'
import { prettyPhone } from '@app/utils/transform'

const props = defineProps({
    lead: {
        type: Object as PropType<Lead>,
        required: true,
    },
})

const availabilityFormatted = computed(() => props.lead.client_information?.availability.map((day) => trans(`recruitment.calendar.days.${day}`)).join(', '))

const shiftTypeFormatted = computed(() =>
    props.lead.client_information?.shift_type.map((shiftType) => trans(`recruitment.client_information.shift_type.${shiftType}`)).join(', ')
)

const hasDriversLicenseFormatted = computed(() => (props.lead.client_information?.has_drivers_license ? trans('form.labels.yes') : trans('form.labels.no')))
</script>

<template>
    <div class="mt-4 grid grid-cols-2 gap-4">
        <DetailItem :label="$t('attributes.email')">{{ lead.email }}</DetailItem>
        <DetailItem :label="$t('attributes.phone_number')">{{ prettyPhone(lead.phone_number) }}</DetailItem>
        <DetailItem :label="$t('attributes.date_of_birth')">{{ getZonedDate(lead.date_of_birth) }}</DetailItem>
        <DetailItem :label="$t('attributes.location')">{{ lead.client_information?.region || '&ndash;' }}</DetailItem>
        <DetailItem :label="$t('attributes.job_preference')">{{ lead.client_information?.job_preference || '&ndash;' }}</DetailItem>
        <DetailItem :label="$t('attributes.education')">{{ lead.client_information?.education || '&ndash;' }}</DetailItem>
        <DetailItem :label="$t('attributes.availability')">{{ availabilityFormatted }}</DetailItem>
        <DetailItem :label="$t('attributes.shift_type')">{{ shiftTypeFormatted }}</DetailItem>
        <DetailItem :label="$t('attributes.has_drivers_license')">{{ hasDriversLicenseFormatted }}</DetailItem>
    </div>
</template>
