<script setup lang="ts">
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { computed, type PropType } from 'vue'
import DismissButton from '../button/DismissButton.vue'

const props = defineProps({
    show: Boolean,
    dismissable: {
        type: Boolean,
        default: true,
    },
    size: {
        type: String as PropType<'lg' | 'xl' | '2xl' | '4xl'>,
        default: 'lg',
    },
})

const modalSizes = {
    lg: 'sm:max-w-lg',
    xl: 'sm:max-w-xl',
    '2xl': 'sm:max-w-2xl',
    '4xl': 'sm:max-w-4xl',
}

const size = computed(() => modalSizes[props.size])

defineEmits(['close'])
</script>

<template>
    <Teleport to="body">
        <TransitionRoot appear as="template" :show="show">
            <Dialog as="div" class="relative z-10" @close="$emit('close')">
                <!-- Overlay -->
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-zinc-700 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div class="fixed inset-0 z-10 overflow-y-auto">
                    <div class="flex min-h-full items-center justify-center p-4 sm:p-0">
                        <TransitionChild
                            as="template"
                            enter="ease-out duration-300"
                            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enter-to="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leave-from="opacity-100 translate-y-0 sm:scale-100"
                            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel
                                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full"
                                :class="size"
                            >
                                <DismissButton v-if="dismissable" @click="$emit('close')" />
                                <slot />
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </Teleport>
</template>
