<script setup lang="ts">
import Card from '@app/components/ui/card/Card.vue'
import type { ContractModel, ContractTemplate } from '@app/types/contracts'
import { useForm, usePage } from '@inertiajs/vue3'
import { route } from 'ziggy-js'
import ContractForm from './ContractForm.vue'

defineEmits(['close'])

const templates = usePage().props.contractTemplates as ContractTemplate[]

const form = useForm<ContractModel>({
    branch_id: null,
    job_title_id: null,
    contract_hour_type_id: null,
    duration_months: null,
    gross_monthly_salary: null,
    min_hours: null,
    max_hours: null,
    start_date: null,
    sign_by: null,
    files: templates.map((template) => ({
        id: template.id,
        name: template.name,
        template: template.template,
    })),
    email: '',
})
</script>

<template>
    <Card :dismissable="true" @close="$emit('close')">
        <div class="text-left text-xl font-semibold leading-7 text-zinc-900">
            {{ $t('contract.create.title') }}
        </div>
        <ContractForm :form :title="$t('contract.create')" :action="route('contract.store')" @content:closed="$emit('close')" />
    </Card>
</template>
