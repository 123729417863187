<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { Authenticatable } from '@app/types/shared'
import { ArrowPathIcon } from '@heroicons/vue/24/solid'
import { computed, type PropType } from 'vue'
import TwoFactorRecoverForm from '../TwoFactorRecoverForm.vue'

const { can } = useUserCan()

const props = defineProps({
    user: {
        type: Object as PropType<Authenticatable>,
        required: true,
    },
})

const { toggle } = useActiveButton()

const show = computed(() => props.user.two_factor_enabled && can('user.two-factor.recover'))
</script>

<template>
    <RoundButton v-if="show" :label="$t('user.actions.recovery_codes')" @click="toggle(TwoFactorRecoverForm)">
        <ArrowPathIcon class="size-4" aria-hidden="true" />
    </RoundButton>
</template>
