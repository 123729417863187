<script setup lang="ts">
import FileDownload from '@app/components/ui/button/FileDownload.vue'
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import ModalLayout from '@app/components/ui/modal/ModalLayout.vue'
import { useLocale } from '@app/composables/useLocale'
import type { Contract, ContractFile } from '@app/types/contracts'
import { formatContractDuration, formatContractHours } from '@app/utils/contracts/contract'
import { getZonedDate } from '@app/utils/date'
import { PaperClipIcon } from '@heroicons/vue/20/solid'
import { computed, ref } from 'vue'
import ContractFooter from '../../sign/ContractFooter.vue'

const props = defineProps<{
    contract: Contract
}>()

const { getLocale, translate } = useLocale()

const duration = computed(() => formatContractDuration(props.contract.duration_months, getLocale()))
const employeeName = computed(() => props.contract.contract_personal_information?.first_name || props.contract.user?.first_name || '')
const showFiles = computed(() => props.contract.status.slug === 'signed' || props.contract.contract_files.some((file) => !!file.generated_contract_path))

const contractPreviewContent = ref('')
const employeeSignature = ref('')
const contractPreviewShown = ref(false)

function showContractPreview(file: ContractFile) {
    contractPreviewContent.value = file.content_preview
    employeeSignature.value = file.signature_path
    contractPreviewShown.value = true
}
</script>

<template>
    <div class="mt-4 grid grid-cols-2 gap-4">
        <DetailItem :label="$t('attributes.job_title_id')">
            {{ translate(contract.job_title.name) }}
        </DetailItem>

        <DetailItem :label="$t('attributes.branch_id')">
            {{ contract.branch.name }}
        </DetailItem>

        <DetailItem :label="$t('attributes.contract_hour_type_id')">
            {{ translate(contract.contract_hour_type.name) }} ({{ formatContractHours(contract.min_hours, contract.max_hours) }})
        </DetailItem>

        <DetailItem :label="$t('attributes.duration_months')">
            {{ $t(duration) }}
        </DetailItem>

        <DetailItem :label="$t('attributes.start_date')">
            {{ getZonedDate(contract.start_date) }}
        </DetailItem>

        <DetailItem v-if="contract.end_date" :label="$t('attributes.end_date')">
            {{ getZonedDate(contract.end_date) }}
        </DetailItem>

        <DetailItem v-if="showFiles" :label="$t('attributes.files')">
            <div v-for="file in contract.contract_files" class="mt-1">
                <FileDownload v-if="file.generated_contract_path" :name="translate(file.contract_template.name)" :path="file.generated_contract_path" />

                <button v-else class="group flex cursor-pointer gap-1" @click="showContractPreview(file)">
                    <PaperClipIcon class="size-5 text-primary-600 group-hover:text-primary-800" />
                    <span class="text-sm font-normal leading-5 text-gray-500 group-hover:text-gray-700">{{ translate(file.contract_template.name) }}</span>
                </button>
            </div>
        </DetailItem>

        <DetailItem :label="$t('attributes.gross_monthly_salary')">
            {{ contract.gross_monthly_salary.formatted }}
        </DetailItem>

        <ModalLayout :show="contractPreviewShown" @close="contractPreviewShown = false" size="4xl">
            <div v-html="contractPreviewContent" class="p-8"></div>

            <ContractFooter :employee-name="employeeName" class="px-8 pb-8">
                <img :src="employeeSignature" class="w-[75%]" />
            </ContractFooter>
        </ModalLayout>
    </div>
</template>
