<script setup lang="ts">
import DeleteShift from '@app/components/shifts/shifts/actions/DeleteShift.vue'
import SetShiftMembers from '@app/components/shifts/shifts/actions/SetShiftMembers.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import type { Shift } from '@app/types/shifts'
import { UserIcon } from '@heroicons/vue/20/solid'
import { type PropType } from 'vue'
import ShiftDetails from './ShiftDetails.vue'
import ShiftMembers from './ShiftMembers.vue'

defineProps({
    shift: {
        type: Object as PropType<Shift>,
        required: true,
    },
})

const emit = defineEmits(['close', 'reload'])
</script>

<template>
    <DetailCard :model="shift" :dismissable="true" @close="emit('close')">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle> Shift ID: {{ shift.id }}</DetailCardTitle>
            <div class="flex flex-col items-center gap-4"></div>
        </div>
        <template #actions>
            <SetShiftMembers :shift="shift" />
            <DeleteShift :shift="shift" />
        </template>
        <template #tabs>
            <DetailCardTab tab="details" label="Details">
                <template #icon>
                    <UserIcon />
                </template>
                <ShiftDetails :shift="shift" />
            </DetailCardTab>
            <DetailCardTab tab="members" label="Members">
                <template #icon>
                    <UserIcon />
                </template>
                <ShiftMembers :shiftMembers="shift.shiftMembers" />
            </DetailCardTab>
        </template>
    </DetailCard>
</template>
