<script setup>
import { useHumanReadableDate } from '@app/composables/date/useHumanReadableDate'
import { computed } from 'vue'

const { getHumanReadableDate } = useHumanReadableDate()

const props = defineProps({
    name: String,
    header: {
        type: String,
        required: true,
    },
    created_at: {
        type: String,
        required: true,
    },
})

const getHeaderName = computed(() => {
    if (props.name === '') {
        return null
    }

    return props.name ?? 'Robot'
})
</script>

<template>
    <p class="text-zinc-900">
        <span class="font-medium">{{ getHeaderName }}</span>
        {{ ' ' + header }}
        <span class="whitespace-nowrap text-xs text-zinc-600">{{ getHumanReadableDate(created_at) }}</span>
    </p>
</template>
