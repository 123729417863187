<script lang="ts" setup>
import ContractSignForm from '@app/components/contracts/sign/ContractSignForm.vue'
import Button from '@app/components/ui/button/Button.vue'
import FileInputZone from '@app/components/ui/form/file/FileInputZone.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useFormWizard } from '@app/composables/form/useFormWizard'
import Guest from '@app/layouts/Guest.vue'
import { useForm } from '@inertiajs/vue3'

defineOptions({
    layout: Guest,
})

const props = defineProps<{
    steps: FormStep[]
    fileTypes: string[]
    fileLimit: number
    files: {
        id_front: string | null
        id_back: string | null
        bank_card_photo: string | null
    }
}>()

const { previousStep, navigateToPreviousStep } = useFormWizard(props.steps)

const form = useForm({
    id_front: props.files.id_front,
    id_back: props.files.id_back,
    bank_card_photo: props.files.bank_card_photo,
})
</script>

<template>
    <ContractSignForm :steps="steps">
        <Form :form="form" @submit="form.post('')">
            <FormSection>
                <FormField class="col-span-12 sm:col-span-6" prop="id_front">
                    <FileInputZone
                        v-model="form.id_front"
                        accept="image/*,application/pdf"
                        :fileTypes="fileTypes"
                        :limit="fileLimit"
                        :label="$t('attributes.id_front')"
                    />
                </FormField>
                <FormField class="col-span-12 sm:col-span-6" prop="id_back">
                    <FileInputZone
                        v-model="form.id_back"
                        accept="image/*,application/pdf"
                        :fileTypes="fileTypes"
                        :limit="fileLimit"
                        :label="$t('attributes.id_back')"
                    />
                </FormField>
                <FormField class="col-span-12 sm:col-span-6" prop="bank_card_photo">
                    <FileInputZone
                        v-model="form.bank_card_photo"
                        accept="image/*,application/pdf"
                        :fileTypes="fileTypes"
                        :limit="fileLimit"
                        :label="$t('attributes.bank_card_photo')"
                    />
                </FormField>
            </FormSection>

            <FormActions>
                <Button type="button" v-if="previousStep" color="white" :label="$t('buttons.back')" @click="navigateToPreviousStep">{{
                    $t('buttons.back')
                }}</Button>
                <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </ContractSignForm>
</template>
