<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import Button from '@app/components/ui/button/Button.vue'
import CreateAvailabilityForm from '@app/components/recruitment/availabilities/CreateAvailabilityForm.vue'
import { computed, ref, watch, inject, type PropType, type Ref } from 'vue'
import { add, endOfWeek, format, isPast, startOfToday, startOfWeek } from 'date-fns'
import Dropdown from '../dropdown/Dropdown.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import { applyFilter } from '@app/utils/filter'
import { useUserCan } from '@app/composables/useUserCan'
import type { User } from '@app/types/shared'
import ModalLayout from '../modal/ModalLayout.vue'

const model = defineModel<Date>({ required: true })

const props = defineProps({
    days: {
        type: Array,
        required: true,
    },
    users: {
        type: Array as PropType<User[]>,
        required: true,
    },
})

const { can } = useUserCan()
const user = inject('availability:user') as Ref<User>

const selectedUser = useModelSelect(props.users, user.value.id)

watch(selectedUser, () => {
    applyFilter({ user_id: selectedUser.value?.id })
})

const firstDaySelectedWeek = computed(() => startOfWeek(model.value, { weekStartsOn: 1 }))

const isPastWeek = computed(() => isPast(endOfWeek(model.value, { weekStartsOn: 1 })))

const addWeeks = (amount: number) => {
    model.value = add(firstDaySelectedWeek.value, { weeks: amount })
}

const selectedMonthYear = computed(() => format(model.value, 'MMMM yyyy'))
const selectedWeek = computed(() => format(model.value, 'w'))

const openAvailability = ref(false)
</script>

<template>
    <header class="flex flex-none flex-wrap items-center justify-between gap-4 border-b border-gray-200 py-4">
        <h1 class="text-xl font-semibold leading-7 text-gray-900">
            <time datetime="2022-01">
                {{ selectedMonthYear }} -
                {{ $t('recruitment.calendar.week') }}
                {{ selectedWeek }}</time
            >
        </h1>
        <div class="flex w-full flex-wrap items-center gap-3 md:gap-6 lg:w-fit lg:flex-nowrap">
            <Dropdown
                v-if="can('recruitment.availability.manage')"
                class="flex-grow"
                :items="users"
                v-model="selectedUser"
                propertyName="full_name"
                :translateDb="false"
            />

            <div class="relative flex flex-grow items-center rounded-md bg-white">
                <span class="isolate inline-flex flex-grow rounded-md">
                    <button
                        @click="addWeeks(-1)"
                        type="button"
                        class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                    >
                        <span class="sr-only">{{ $t('recruitment.calendar.previous_week') }}</span>
                        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                        @click="model = startOfToday()"
                        type="button"
                        class="relative -ml-px flex-grow border border-gray-300 bg-white px-4 py-2 text-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                    >
                        {{ $t('recruitment.calendar.today') }}
                    </button>
                    <button
                        @click="addWeeks(1)"
                        type="button"
                        class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                    >
                        <span class="sr-only">{{ $t('recruitment.calendar.next_week') }}</span>
                        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </span>
            </div>
            <div class="hidden h-6 w-px bg-gray-300 lg:block"></div>
            <Button :disabled="isPastWeek" color="primary" @click="openAvailability = true">
                {{ $t('recruitment.calendar.add') }}
            </Button>

            <ModalLayout :show="openAvailability" @close="openAvailability = false">
                <CreateAvailabilityForm :days="days" :selectedWeek="selectedWeek" @close="openAvailability = false" />
            </ModalLayout>
        </div>
    </header>
</template>
