<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useForm, usePage } from '@inertiajs/vue3'
import VueDatePicker from '@vuepic/vue-datepicker'
import { useLocale } from '@app/composables/useLocale'
import Form from '@app/components/ui/form/Form.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import { getZonedDate } from '@app/utils/date'
import type { Lead } from '@app/types/recruitment'
import { route } from 'ziggy-js'
import MultiToggle from '@app/components/ui/multiToggle/MultiToggle.vue'
import MultiToggleOption from '@app/components/ui/multiToggle/MultiToggleOption.vue'
import Checkbox from '@app/components/ui/form/Checkbox.vue'
import type { PageProps } from '@app/types/inertia'

const props = defineProps<{ lead: Lead; title?: string }>()
const emit = defineEmits(['close'])

const { getLocale } = useLocale()

const clients = computed(() => usePage<PageProps>().props.recruitmentSelectionClients)
const selectedClient = useModelSelect(clients, clients.value[0])

const form = useForm({
    first_name: props.lead.first_name || '',
    last_name: props.lead.last_name || '',
    phone_number: props.lead.phone_number || '',
    email: props.lead.email || '',
    date_of_birth: props.lead.date_of_birth ? new Date(props.lead.date_of_birth) : null,
    region: props.lead.client_information?.region || '',
    education: props.lead.client_information?.education || '',
    availability: props.lead.client_information?.availability || [],
    shift_type: props.lead.client_information?.shift_type || ['day'],
    job_preference: props.lead.client_information?.job_preference || '',
    has_drivers_license: props.lead.client_information?.has_drivers_license || false,
    client_id: props.lead.client_information?.client_id || selectedClient.value?.id,
})

const dateInputOptions = ref({
    format: 'dd-MM-yyyy',
})

const update = () => {
    form.transform((data) => ({
        ...data,
        date_of_birth: getZonedDate(data.date_of_birth),
    })).put(route('recruitment.lead.update-client-information', props.lead.id), {
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
            emit('close')
        },
    })
}

const availabilities = [
    { value: 'mon', label: 'recruitment.calendar.days.mon' },
    { value: 'tue', label: 'recruitment.calendar.days.tue' },
    { value: 'wed', label: 'recruitment.calendar.days.wed' },
    { value: 'thu', label: 'recruitment.calendar.days.thu' },
    { value: 'fri', label: 'recruitment.calendar.days.fri' },
    { value: 'sat', label: 'recruitment.calendar.days.sat' },
    { value: 'sun', label: 'recruitment.calendar.days.sun' },
]

const shiftTypes = computed(() => [
    { slug: 'day', name: 'recruitment.client_information.shift_type.day' },
    { slug: 'night', name: 'recruitment.client_information.shift_type.night' },
    { slug: 'both', name: 'recruitment.client_information.shift_type.both' },
])

const selectedShiftType = ref(getInitialShiftType())

watch(selectedShiftType, () => {
    if (selectedShiftType.value?.slug === 'both') {
        form.shift_type = ['day', 'night']
    } else {
        form.shift_type = [selectedShiftType.value?.slug]
    }
})

function getInitialShiftType() {
    if (!props.lead.client_information) {
        return shiftTypes.value.find(({ slug }) => slug === 'day')
    }

    if (props.lead.client_information.shift_type.length === 2) {
        return shiftTypes.value.find(({ slug }) => slug === 'both')
    }

    return shiftTypes.value.find(({ slug }) => slug === props.lead.client_information?.shift_type[0])
}
</script>

<template>
    <Form :form="form" @submit="update">
        <FormSection :title="title">
            <FormField class="col-span-12 sm:col-span-6" prop="first_name" :label="$t('attributes.first_name')">
                <Input id="first_name" v-model="form.first_name" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="last_name" :label="$t('attributes.last_name')">
                <Input id="last_name" v-model="form.last_name" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="phone_number" :label="$t('attributes.phone_number')">
                <Input id="phone_number" v-model="form.phone_number" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="email" :label="$t('attributes.email')">
                <Input id="email" type="email" v-model="form.email" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="date_of_birth" :label="$t('attributes.date_of_birth')">
                <VueDatePicker
                    id="date_of_birth"
                    v-model="form.date_of_birth"
                    :locale="getLocale().code"
                    :auto-position="false"
                    auto-apply
                    :enable-time-picker="false"
                    text-input
                    :text-input-options="dateInputOptions"
                    format="dd-MM-yyyy"
                />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="region" :label="$t('attributes.location')">
                <Input id="region" type="text" v-model="form.region" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="education" :label="$t('attributes.education')">
                <Input id="education" type="text" v-model="form.education" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="job_preference" :label="$t('attributes.job_preference')">
                <Input id="job_preference" type="text" v-model="form.job_preference" autocomplete="off" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="client_id" :label="$t('attributes.client')">
                <Dropdown id="client_id" :items="clients" v-model="selectedClient" v-model:form="form.client_id" propertyName="name" />
            </FormField>

            <FormField class="col-span-12 sm:col-span-6" prop="shift_type" :label="$t('attributes.shift_type')">
                <Dropdown id="shift_type" :items="shiftTypes" v-model="selectedShiftType" propertyName="name" by="slug" />
            </FormField>

            <FormField class="col-span-12" prop="availability" :label="$t('attributes.availability')">
                <MultiToggle v-model="form.availability">
                    <MultiToggleOption v-for="{ value, label } in availabilities" :value="value">
                        {{ $t(label) }}
                    </MultiToggleOption>
                </MultiToggle>
            </FormField>

            <FormField class="col-span-12" prop="has_drivers_license">
                <Checkbox v-model="form.has_drivers_license">
                    {{ $t('attributes.has_drivers_license') }}
                </Checkbox>
            </FormField>
        </FormSection>

        <FormActions>
            <CancelButton @click="$emit('close')" />
            <SaveButton :disabled="form.processing" />
        </FormActions>
    </Form>
</template>
